<template>
    <r-e-dialog title="附属商铺" :visible.sync="dialogVisible" show-footer top="10vh"
                @click-submit="clickSubmit" @click-cancel="clickCancel">
        <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
            <el-form-item label="商铺编号" prop="shopsNumber">
                <el-input v-model="formPublish.shopsNumber" placeholder="请输入商铺编号"/>
            </el-form-item>
            <div class="flex">
                <el-form-item label="所属区域" prop="province">
                    <el-select v-model="formPublish.province" placeholder="请选择省" @change="provinceChange">
                        <el-option v-for="(item,index) in provinceList" :key="index" :label="item.name" :value="item.name"/>
                    </el-select>
                </el-form-item>
                <el-form-item label-width="10px" prop="city">
                    <el-select v-model="formPublish.city" placeholder="请选择市" @change="cityChange">
                        <el-option v-for="(item,index) in cityList" :key="index" :label="item.name" :value="item.name"/>
                    </el-select>
                </el-form-item>
                <el-form-item label-width="10px" prop="area">
                    <el-select v-model="formPublish.area" placeholder="请选择区">
                        <el-option v-for="(item,index) in areaList" :key="index" :label="item.name" :value="item.name"/>
                    </el-select>
                </el-form-item>
            </div>
            <el-form-item label="所在商圈" prop="belongingDistrict">
                <el-input v-model="formPublish.belongingDistrict" placeholder="请输入所在商圈"/>
            </el-form-item>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import cityCode from "@/assets/json/city_code.json";
    export default {
        name: "layer-add-subsidiary-shops",
        data() {
            return {
                dialogVisible: false,
                houseTypes: [],
                formPublish: {
                    shopsNumber: null,
                    province: null,
                    city: null,
                    area: null,
                    belongingDistrict: null,
                },
                rules:{
                    shopsNumber: [{ required: true, message: '请输入商铺编号', trigger: 'blur' }],
                    province: [{ required: true, message: '请选择省', trigger: 'change' }],
                    city: [{ required: true, message: '请选择市', trigger: 'change' }],
                    area: [{ required: true, message: '请选择区', trigger: 'change' }],
                    belongingDistrict: [{ required: true, message: '请输入所在商圈', trigger: 'blur' }],
                },
                provinceList: cityCode,
                cityList: [],
                areaList: [],
            };
        },
        components: {},
        async mounted() {
        },
        methods: {
            openDialog(data) {
                this.dialogVisible = true;
            },
            clickSubmit() {
                this.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        this.$parent.tableData.push({...this.formPublish,uuid: Date.now()});
                        this.clickCancel();
                    }
                });
            },
            clickCancel() {
                this.$refs["formPublish"].resetFields();
                this.cityList = [];
                this.areaList = [];
                this.dialogVisible = false;
            },
            provinceChange(value) {
                const {city, code, name} = this.provinceList.find(i => i.name === value);
                this.formPublish.city = null;
                this.formPublish.area = null;
                this.cityList = city;
                this.areaList = [];
            },
            cityChange(value) {
                const {area, code, name} = this.cityList.find(i => i.name === value);
                this.formPublish.area = null;
                this.areaList = area;
            }
        }
    }
</script>

<style scoped>

</style>